<template>
  <div class="about">
    <header class="header1">
      <img
        class="header-bg"
        src="https://prod.oss.netopstec.com/net-home-banner3.png"
        alt=""
      />
      <h4>ABOUT NETOPS</h4>
      <p>以“注力品牌，连接你我”为使命，网营科技聚焦美妆、母婴、宠物、食品、服饰等五大领域，通过近12年电商行业经验，为超200家国内外一线品牌和新兴品牌布局线上零售渠道，定制内容营销服务、提供数据化解决方案、全方位助力品牌实现线上营销增量。</p>
    </header>
    <header class="header2">
      <img
        class="header-bg"
        src="https://prod.oss.netopstec.com/net-home-banner-h5.png"
        alt=""
      />
      <h4>ABOUT NETOPS</h4>
      <p>以“注力品牌，连接你我”为使命，网营科技聚焦美妆、母婴、宠物、食品、服饰等五大领域，通过近12年电商行业经验，为超200家国内外一线品牌和新兴品牌布局线上零售渠道，定制内容营销服务、提供数据化解决方案、全方位助力品牌实现线上营销增量。
      </p>
    </header>
    <div ref="box_title1" class="box_title">
      <h6>使命愿景</h6>
    </div>
    <div ref="case"  v-if="IsPC()" class="case">
      <div class="Viewing_area">
        <div ref="Theslider" class="Theslider">
          <div class="brander">
            <img
              class="bg"
              ref="caseHeight"
              src="https://prod.oss.netopstec.com/net-about-Mission2.png"
              alt=""
            />
            <div class="body">
              <div class="left"></div>
              <div class="right">
                <p>网营 使命</p>
                <span>注力品牌 连接你我</span>
              </div>
            </div>
          </div>
          <div class="brander">
            <img
              class="bg"
              ref="caseHeight"
              src="https://prod.oss.netopstec.com/net-about-Mission2-t.png"
              alt=""
            />
            <div class="body">
              <div class="left"></div>
              <div class="right">
                <p>网营 愿景</p>
                <span>缔造全球电子商务极致服务 NO.1</span>
              </div>
            </div>
          </div>
          <div class="brander">
            <img
              class="bg"
              ref="caseHeight"
              src="https://prod.oss.netopstec.com/net-about-Mission22.png"
              alt=""
            />
            <div class="body">
              <div class="left"></div>
              <div class="right">
                <p style="width: 200px">
                  网营 <br />
                  价值观
                </p>
                <span>客户第一  拥抱变化</span>
              </div>
            </div>
          </div>
          <div class="brander">
            <img
              class="bg"
              ref="caseHeight"
              src="https://prod.oss.netopstec.com/net-about-Mission2.png"
              alt=""
            />
            <div class="body">
              <div class="left"></div>
              <div class="right">
                <p>网营 使命</p>
                <span>注力品牌 连接你我</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <div
          @click="handleCaseIndex(1)"
          :class="caseIndex == 1 || caseIndex == 4 ? 'dian red' : 'dian'"
        ></div>
        <div
          @click="handleCaseIndex(2)"
          :class="caseIndex == 2 ? 'dian red' : 'dian'"
        ></div>
        <div
          @click="handleCaseIndex(3)"
          :class="caseIndex == 3 ? 'dian red' : 'dian'"
        ></div>
      </div>
      <img
        class="gdxl"
        @click="handleCaseIndex(false)"
        src="https://prod.oss.netopstec.com/net-gdxl1.png"
        alt=""
      />
    </div>
    <div ref="case" id="case" v-else class="case">
      <div class="Viewing_area">
        <div ref="Theslider" class="Theslider">
          <div class="brander">
            <img
              class="bg"
              ref="caseHeight"
              src="https://prod.oss.netopstec.com/net-about-Mission2-h5.png"
              alt=""
            />
            <div class="body">
              <div class="left"></div>
              <div class="right">
                <p>网营使命</p>
                <span>注力品牌 连接你我</span>
              </div>
            </div>
          </div>
          <div class="brander">
            <img
              class="bg"
              ref="caseHeight"
              src="https://prod.oss.netopstec.com/net-about-Mission2-t-h5.png"
              alt=""
            />
            <div class="body">
              <div class="left"></div>
              <div class="right">
                <p>网营愿景</p>
                <span>缔造全球电子商务极致服务 NO.1</span>
              </div>
            </div>
          </div>
          <div class="brander">
            <img
              class="bg"
              ref="caseHeight"
              src="https://prod.oss.netopstec.com/net-about-Mission22-h5.png"
              alt=""
            />
            <div class="body">
              <div class="left"></div>
              <div class="right">
                <p style="width: 200px">
                  网营价值观
                </p>
                <span>客户第一  拥抱变化</span>
              </div>
            </div>
          </div>
          <div class="brander">
            <img
              class="bg"
              ref="caseHeight"
              src="https://prod.oss.netopstec.com/net-about-Mission2-h5.png"
              alt=""
            />
            <div class="body">
              <div class="left"></div>
              <div class="right">
                <p>网营使命</p>
                <span>注力品牌 连接你我</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <div
          @click="handleCaseIndex(1)"
          :class="caseIndex == 1 || caseIndex == 4 ? 'dian red' : 'dian'"
        ></div>
        <div
          @click="handleCaseIndex(2)"
          :class="caseIndex == 2 ? 'dian red' : 'dian'"
        ></div>
        <div
          @click="handleCaseIndex(3)"
          :class="caseIndex == 3 ? 'dian red' : 'dian'"
        ></div>
      </div>
    </div>

    <div ref="course" v-if="IsPC()" class="course">
      <img
        class="xian"
        src="https://prod.oss.netopstec.com/net-about-course-img1.png"
        alt=""
      />
      <li class="li1">
        <span>2009</span>
      </li>
      <li class="li2">
        <span>2010</span>
      </li>
      <li class="li3">
        <span>2011</span>
      </li>
      <li class="li4">
        <span>2014</span>
      </li>
      <li class="li5">
        <span>2016</span>
      </li>
      <li class="li6">
        <span>2017</span>
      </li>
      <li class="li7">
        <span>2018</span>
      </li>
      <li class="li8">
        <span>2019</span>
      </li>
      <li class="li9">
        <span>2020</span>
      </li>
      <li class="li10">
        <span>2021</span>
      </li>

      <h6>网营历程</h6>
      <span class="span1">本公司根据中国法律成立</span>
      <span class="span2">
        成为淘宝上首批品牌服务提供商<br>欧美最畅销的婴儿奶瓶品牌Dr-Brown's成为我们的品牌合作伙伴</span
      ><span class="span3"> 首批成功开发自有电子商务ERP及CRM系统的品牌电子商务服务提供商</span
      ><span class="span4"
        >成为阿里巴巴集团成立的“御膳房数据联盟委员会”委员 </span
      ><span class="span5">成为淘宝的首批内容合作伙伴 </span
      ><span class="span6">与欧莱雅集团订立合约，并成为该集团的指定内容营销服务提供商</span
      ><span class="span7"
        >不断深化数据赋能专业，获天猫认定为“数据银行认证服务商”<br>
        荣获“天猫五星服务商”称号</span
      ><span class="span8">成为京东的战略合作伙伴，并加入“京东美力联盟”<br>获认证为“淘宝 MCN机构”及“淘宝店运营及直播服务商”</span
      ><span class="span9"
        >启动全渠道电子商务运营 <br />
        战略性地将我们的足迹扩展到宠物产品市场</span
      ><span class="span10"
        >获认可为抖音认证品牌服务提供商<br />
        获天猫授予为年度宠物用品金牌服务提供商</span
      >
    </div>
    <div ref="course" v-else class="course">
      <img
        class="xian"
        src="https://prod.oss.netopstec.com/net-about-course-img1.png"
        alt=""
      />
      <li
        @click="courseIndex = 1"
        :class="courseIndex == 1 ? 'select li1' : 'li1'"
      >
        <span>2009</span>
      </li>
      <li
        @click="courseIndex = 2"
        :class="courseIndex == 2 ? 'select li2' : 'li2'"
      >
        <span>2010</span>
      </li>
      <li
        @click="courseIndex = 3"
        :class="courseIndex == 3 ? 'select li3' : 'li3'"
      >
        <span>2011</span>
      </li>
      <li
        @click="courseIndex = 4"
        :class="courseIndex == 4 ? 'select li4' : 'li4'"
      >
        <span>2014</span>
      </li>
      <li
        @click="courseIndex = 5"
        :class="courseIndex == 5 ? 'select li5' : 'li5'"
      >
        <span>2016</span>
      </li>
      <li
        @click="courseIndex = 6"
        :class="courseIndex == 6 ? 'select li6' : 'li6'"
      >
        <span>2017</span>
      </li>
      <li
        @click="courseIndex = 7"
        :class="courseIndex == 7 ? 'select li7' : 'li7'"
      >
        <span>2018</span>
      </li>
      <li
        @click="courseIndex = 8"
        :class="courseIndex == 8 ? 'select li8' : 'li8'"
      >
        <span>2019</span>
      </li>
      <li
        @click="courseIndex = 9"
        :class="courseIndex == 9 ? 'select li9' : 'li9'"
      >
        <span>2020</span>
      </li>
      <li
        @click="courseIndex = 10"
        :class="courseIndex == 10 ? 'select li10' : 'li10'"
      >
        <span>2021</span>
      </li>

      <h6>网营历程</h6>
      <span v-show="courseIndex == 1" class="span">本公司根据中国法律成立</span>
      <span v-show="courseIndex == 2" class="span">
        成为淘宝上首批品牌服务提供商<br>
        欧美最畅销的婴儿奶瓶品牌Dr-Brown's成为我们的品牌合作伙伴</span
      ><span v-show="courseIndex == 3" class="span">
        首批成功开发自有电子商务ERP及CRM系统的品牌电子商务服务提供商 </span
      ><span v-show="courseIndex == 4" class="span"
        >成为阿里巴巴集团成立的“御膳房数据联盟委员会”委员 </span
      ><span v-show="courseIndex == 5" class="span"
        >成为淘宝的首批内容合作伙伴 </span
      ><span v-show="courseIndex == 6" class="span"
        >与欧莱雅集团订立合约，并成为该集团的指定内容营销服务提供商</span
      ><span v-show="courseIndex == 7" class="span"
        >不断深化数据赋能专业，获天猫认定为“数据银行认证服务商”<br>
        荣获“天猫五星服务商”称号</span
      ><span v-show="courseIndex == 8" class="span"
        >成为京东的战略合作伙伴，并加入“京东美力联盟”<br>
        获认证为“淘宝 MCN机构”及“淘宝店运营及直播服务商”</span
      ><span v-show="courseIndex == 9" class="span"
        >启动全渠道电子商务运营<br>
        战略性地将我们的足迹扩展到宠物产品市场</span
      ><span v-show="courseIndex == 10" class="span">获认可为抖音认证品牌服务提供商<br>
        获天猫授予为年度宠物用品金牌服务提供商</span
      >
    </div>
    <div ref="dynamic" v-if="IsPC()" class="dynamic">
      <div ref="box_title2" class="box_title">
        <h6>资质荣誉</h6>
      </div>
      <img
        class="xian"
        src="https://prod.oss.netopstec.com/about-medal-xian.png	"
        alt=""
      />
      <div class="left">
        <img
          v-for="(i, index) in 12"
          :key = index
          :src="`https://prod.oss.netopstec.com/medal0${i}.png`"
          alt=""
        />
      </div>
      <div class="right">
        <li>天猫母婴亲子“最佳运营服务商”</li>
        <li>天猫金妆奖“黑马新品牌奖”</li>
        <li>天猫零食行业“双11超级大牌”</li>
        <li>天猫宠物“最佳黑马新人奖”</li>
        <li>天猫母婴奶粉行业最佳运营奖</li>
        <li>天猫母婴消费者新趋势奖</li>
        <li>天猫乳饮冰行业最佳合作伙伴</li>
        <li>天猫食品行业优秀服务商奖</li>
        <li>阿里金麦奖“最佳视觉服务奖”</li>
        <li>阿里巴巴UACE设计大奖</li>
        <li>天猫母婴类目金牌淘拍档</li>
      </div>
    </div>
    <div ref="dynamic" v-else class="dynamic">
      <div ref="box_title2" class="box_title">
        <h6>资质荣誉</h6>
      </div>
      <!-- <img
        class="xian"
        src="https://prod.oss.netopstec.com/about-medal-xian.png	"
        alt=""
      /> -->
      <div class="left">
        <div class="box">
          <img src="https://prod.oss.netopstec.com/medal01.png" alt="" />
          <p>天猫金妆奖<br>黑马新品牌奖</p>
        </div>
        <div class="box">
          <img src="https://prod.oss.netopstec.com/medal02.png" alt="" />
          <p>天猫母婴亲子<br>最佳运营服务商</p>
        </div>
        <div class="box">
          <img src="https://prod.oss.netopstec.com/medal03.png" alt="" />
          <p>天猫宠物<br>最佳黑马新人奖</p>
        </div>
        <div class="box">
          <img src="https://prod.oss.netopstec.com/medal04.png" alt="" />
          <p>天猫零食行业<br>双11超级大牌</p>
        </div>
        <div class="box">
          <img src="https://prod.oss.netopstec.com/medal05.png" alt="" />
          <p>天猫母婴亲子<br>最佳运营服务商</p>
        </div>
        <div class="box">
          <img src="https://prod.oss.netopstec.com/medal06.png" alt="" />
          <p>天猫母婴奶粉<br>行业最佳运营奖</p>
        </div>
        <div class="box">
          <img src="https://prod.oss.netopstec.com/medal07.png" alt="" />
          <p>天猫乳饮冰<br>行业最佳合作伙伴</p>
        </div>
        <div class="box">
          <img src="https://prod.oss.netopstec.com/medal08.png" alt="" />
          <p>天猫母婴类目<br>金牌淘拍档</p>
        </div>
      </div>
    </div>
    <div class="footer" v-if="!IsPc">
        <div class="showDialog">
          <div @click="openModel('使用条款')">使用条款</div>
          <div @click="openModel('个人信息保护政策')">个人信息保护政策</div>
        </div>
        <p>版权所有:杭州网营科技股份有限公司</p>
        <p>
            Copyright@2015 Netopstec.All Rights Reserved <a href="https://www.beian.gov.cn/portal/registerSystemInfo"><img src="https://prod.oss.netopstec.com/gongan.png" alt="" style="display: inline-block;
          "/></a> 浙公网安备33018302001367号
            <a href="https://beian.miit.gov.cn/">浙ICP备12046102号</a>
        </p>
    </div>
    <div class="footer" v-else>
        <p>版权所有:杭州网营科技股份有限公司</p>
        <p>
            Copyright@2015 Netopstec.All Rights Reserved

        </p>
        <a href="https://www.beian.gov.cn/portal/registerSystemInfo"><img src="https://prod.oss.netopstec.com/gongan.png" alt="" style="display: inline-block"/></a> 浙公网安备33018302001367号
        <a href="https://beian.miit.gov.cn/">浙ICP备12046102号</a>
    </div>
    <modelbox :showData="selectData" :show="showModel" :clone="handleClone">
    </modelbox>
  </div>
</template>

<script>
import modelbox from "../components/agreementBox.vue";
export default {
  components: { modelbox },
  data() {
    return {
      showModel: false,
      selectData: {},
      courseIndex: 1, //移动端网营历程 点击控制器
      caseIndex: 1,
      caseStatus: false, //轮播图函数只执行一次的控制器
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    setTimeout(() => {
        this.caseMove();
      }, 500);

      setInterval(() => {
        if(this.courseIndex==10){
          this.courseIndex = 1
        }else{
          this.courseIndex+=1
        }
      }, 3500);
  },
  methods: {
    handleClone() {
      this.showModel = false;
    },
    openModel(item) {
      // this.selectData = item;
      this.selectData.title = item;
      if(item == '个人信息保护政策') {
        this.selectData.content = `
        <p class="text">生效日期：2023年  6月  12日</p>
        <p class="text">版本编号：V1.0.0</p>
        <p class="text">杭州网营科技股份有限公司（注册地址：浙江省杭州市西湖区双龙街199号杭政储出【2013】51号地块商业商务用房5#10层1080室）（“我们”）非常重视您的个人信息保护。本《个人信息保护政策》（“本政策”）列明了当您浏览NETOPS网营网站（“本网站”）时，我们如何收集、使用和保护您的个人信息。</p>
        <p class="text">1.	我们如何收集和使用个人信息</p>
        <p>当您浏览本网站时，我们会收集您的IP地址，我们不会将收集的IP地址和其他信息结合识别您的身份。</p>
        <p>2.	收集和使用个人信息的法律基础</p>
        <p>我们会在具备法律基础的情况下收集和处理您的个人信息，包括同意和其他法律基础。</p>
        <p>3.	共享、披露、转移个人信息</p>
        <p>3.1	我们仅会在取得您的同意或具备其他法律基础时，向第三方共享、披露您的个人信息。</p>
        <p>3.2	如果因为合并、分立、解散、被宣告破产等原因需要转移您的个人信息，我们将会告知您接收方的名称或者姓名和联系方式。按照法律规定，接收方应继续履行个人信息处理者的义务。</p>
        <p>4.	数据出境</p>
        <p>我们收集的个人信息存储于中国境内的服务器，不向中国境外传输。</p>
        <p>5.	Cookie和同类技术</p>
        <p>为优化在您计算机屏幕上显示的界面和统计访客流量，我们在本网站上使用了Cookie和其他同类技术，收集和处理您的访问网页记录、访问网页的日期和次数、个人常用设备信息、硬件型号、设备MAC地址。</p>
        <p>6.	保存期限</p>
        <p>我们将仅在实现处理目的所需的必要时间内保存您的个人信息。</p>
        <p>7.	个人信息安全事件处置</p>
        <p>如发生您的个人信息未经授权的访问或泄露、篡改、丢失的，我们将立即采取补救措施，并依法履行通知义务。</p>
        <p>8.	您的合法权利</p>
        <p>8.1	在您的个人信息处理活动中，您依法享有查阅、复制权，更正、补充权，删除权，撤回同意权等权利。</p>
        <p>8.2	我们一般会在十五个工作日内响应您的权利请求。如果涉及的情况复杂而需要更长时间处理，我们会在十五个工作日届满前告知您。</p>
        <p>8.3	如果行使上述权利的要求不能实现，我们将说明理由。</p>
        <p>9.	安全保护措施</p>
        <p>我们会采取适当的安全措施保护您的个人信息，防止您的个人信息遭受未经授权的访问以及泄露、篡改、丢失。</p>
        <p>10.	第三方产品和服务</p>
        <p>我们在提供产品和服务时，可能会使用第三方产品和服务。使用第三方产品和服务时，服务条款和个人信息收集适用第三方服务提供者的协议和个人信息保护政策。</p>
        <p>11.	本政策的变更</p>
        <p>本政策可能会不时进行变更，我们将会及时在本网站发布修订后的版本。</p>
        <p>12.	联系我们</p>
        <p>如果您对本政策有任何疑问，请通过0571-87041697联系我们。</p>
        <p>如果您对我们的回复不满意，您也可以向您所在地区的相关监管机构投诉，或者联系我们，我们会根据您的实际情况，提供可能适用的投诉途径。</p>
        `
      }else{
        this.selectData.content = `
          <p>欢迎访问NETOPS网营网站（“本网站”）！用户使用本网站，即表示用户同意本使用条款（“本条款”）。请仔细阅读并根据本条款的规定使用本网站。</p>
          <p>本网站系由杭州网营科技股份有限公司（“网营科技”）运营。</p>
          <p>1.	适用范围</p>
          <p>本条款适用于用户访问和浏览本网站。</p>
          <p>2.	所有权和知识产权</p>
          <p>本网站包含的所有代码、技术、软件、程序、应用、数据和信息（包括文字、图像、图片、照片、音频、视频、图表、色彩、版面设计、电子文档）的著作权、商标权、专利权、商业秘密、外观设计等的所有权、知识产权和其他权益均属网营科技所有或者由网营科技获得合法授权而使用。用户不得以任何方式侵犯网营科技的上述权益。</p>
          <p>3.	使用规范</p>
          <p>访问本网站和使用本网站上的产品和服务，应当遵循合法、公平、合理的原则。</p>
          <p>用户通过本网站提供的信息应当是真实的、正确的和完整的。</p>
          <p>用户不可使用本网站从事任何非法活动、侵犯任何组织和个人的合法权益或进行其他本条款禁止的活动，包括：</p>
          <p>(a)	违反本条款第2条的规定，侵犯网营科技的所有权、知识产权和其他权益；</p>
          <p>(b)	对本网站进行改变、反编译、反汇编等反向工程处理；</p>
          <p>(c)	违反法律规定使用本网站，损害他人尊严、侵犯他人隐私或他人其他合法权益；</p>
          <p>(d)	使用本网站存储或传输恶意代码；</p>
          <p>(e)	干扰、破坏本网站或其涉及的第三方应用的功能、数据的完整性；</p>
          <p>(f)	发表、传播具有诽谤、辱骂或淫秽性质或含有非法内容的任何材料；</p>
          <p>(g)	其他非法的、侵犯任何组织和个人的合法权益的或本条款禁止的活动。</p>
          <p>4.	第三方产品和服务</p>
          <p>本网站可能会使用第三方产品和服务。使用第三方产品和服务时，使用条款和个人信息处理适用第三方服务提供者的协议和个人信息保护政策。</p>
          <p>5.	个人信息保护</p>
          <p>网营科技将会根据本网站的《个人信息保护政策》收集和使用用户的个人信息。</p>
          <p>6.	本网站的维护、更新或暂停</p>
          <p>本网站及其内容基于“现状”和“现有”提供。网营科技对本网站的可用性、持续性、可访问性，不做任何形式的保证。</p>
          <p>网营科技保留在任何时候不经通知进行以下行为的权利：</p>
          <p>(a)	基于任何原因，暂停或终止本网站全部或部分的运行和访问；</p>
          <p>(b)	在进行定期或非定期维护、更新或其他调整时，中止、中断或限制使用本网站全部或部分功能。</p>
          <p>7.	违约后果</p>
          <p>如果用户违反本条款，网营科技有权采取网营科技认为必要的以下一项或多项补救措施：</p>
          <p>(a)	删除发布的非法内容；</p>
          <p>(b)	限制或禁止用户访问本网站；</p>
          <p>(c)	采取其他补救措施。</p>
          <p>8.	适用法律和争议解决</p>
          <p>本条款的订立、执行、解释和相关争议解决适用中国法律。</p>
          <p>凡因本条款引起的或与本条款有关的任何争议，双方可协商解决。如双方在30日内未达成共同认可的解决方案，任何一方可将争议提请杭州仲裁委员会进行仲裁，按照仲裁申请时该仲裁委员会现行有效的仲裁规则进行仲裁。仲裁地点在杭州，仲裁裁决是终局的，对双方均有约束力。</p>
          <p>9.	联系与反馈</p>
          <p>如果用户对本条款有任何问题和建议，请通过以下方式联系网营科技：</p>
          <p>联系方式	0571-87041697</p>

        `
      }
      
      this.showModel = true;
    },
    handleCase() {
      //案例展示计时器
      setInterval(() => {
        if (this.caseIndex >= 4) {
          this.caseIndex = 1;
          setTimeout(() => {
            this.caseIndex += 1;
          }, 100);
        } else {
          this.caseIndex += 1;
        }
      }, 7000);
    },
    handleCaseIndex(num) {
      //案例轮播图
      if (typeof num  == Number) {
        this.caseIndex = num;
      } else if(!num){
        if (this.caseIndex == 4) {
          this.caseIndex = 1;
          setTimeout(() => {
            this.caseIndex += 1;
          }, 300);
        } else {
          this.caseIndex += 1;
        }
      }else{
        if (this.caseIndex == 1) {
          this.caseIndex = 4;
          setTimeout(() => {
            this.caseIndex -= 1;
          }, 300);
        } else {
          this.caseIndex -= 1;
        }
      }
    },
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    },
    caseMove() {
      //案例展示轮播图 滑过事件
      let that = this
      let casedom = document.getElementById("case");
      casedom.ontouchstart = function (e) {
        var one  = e.touches[0].pageX
        casedom.ontouchend = function (e) {
          var two = e.changedTouches[0].pageX
          if(one>two){
            // console.log('向左')
            that.handleCaseIndex(false)
          }else{
            // console.log('向右')
          }
        };
      };
    },
    handleScroll(e) {
      //滚动事件
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (
        scrollTop >=
        this.$refs.box_title1.offsetTop -
          this.$refs.box_title1.offsetHeight * 1.7
      ) {
        this.$refs.box_title1.className = "box_title box_title1_ini";
        this.$refs.case.className = "case case_ini";
        if (!this.caseStatus) {
          this.handleCase();
        }
        this.caseStatus = true;
      }
      if (
        scrollTop >=
        this.$refs.dynamic.offsetTop - (this.$refs.dynamic.offsetHeight + 200)
      ) {
        this.$refs.box_title2.className = "box_title box_title2_ini";
        this.$refs.dynamic.className = "dynamic dynamic_ini";
      }

      if (
        scrollTop >=
        this.$refs.course.offsetTop - this.$refs.course.offsetHeight * 0.7
      ) {
        this.$refs.course.className = "course course_ini";
      }
    },
  },
  watch: {
    caseIndex(news, old) {
      if (this.IsPC()) {
        if (news == 1) {
          this.$refs.Theslider.className = "Theslider Thesliders";
          this.$refs.Theslider.style.top = "0px";
          setTimeout(() => {
            this.$refs.Theslider.className = "Theslider";
          }, 100);
        } else {
          this.$refs.Theslider.style.top =
            -this.$refs.caseHeight.offsetHeight * (news - 1) + "px";
        }
      } else {
        if (news == 1) {
          this.$refs.Theslider.className = "Theslider Thesliders";
          this.$refs.Theslider.style.left = "0px";
          setTimeout(() => {
            this.$refs.Theslider.className = "Theslider";
          }, 100);
        } else {
          this.$refs.Theslider.style.left =
            -this.$refs.caseHeight.offsetWidth * (news - 1) + "px";
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 1100px) {
  .header2 {
    display: none;
  }
  .header1 {
    position: relative;
    .header-bg {
      width: 100%;
      position: relative;
      z-index: -1;
    }
    h4 {
      position: absolute;
      left: 686px;
      top: 323px;
      font-size: 70px;
      letter-spacing: 5px;
      font-weight: bold;
      color: #ffffff;
      line-height: 69px;
      text-shadow: 0 0 2px #e5dede;
      animation: bannerLogo 1.5s linear 1;
    }
    p {
      position: absolute;
      top: 450px;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.4);
      text-align: center;
      left: 176px;
      padding: 39px;
      width: 1491px;
      height: 80px;
      font-size: 22px;
      font-weight: 400;
      color: #ffffff;
      line-height: 45px;
      animation: bannerLogo 1.5s 0.5s linear 1;
      animation-fill-mode: forwards;
    }
  }
  .box_title {
    background: white;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    width: 1920px;
    height: 440px;
    h6 {
      background: url("https://prod.oss.netopstec.com/net-box_title-bg3.png")
        no-repeat center center;
      background-size: 100% 100%;
      width: 526px;
      height: 124px;
      line-height: 124px;
      align-items: center;
      text-align: center;
      position: absolute;
      top: 171px;
      font-size: 40px;
      font-weight: 500;
      span {
        color: #982037;
      }
    }
    p {
      position: absolute;
      top: 0;
      margin-top: 306px;
      font-size: 25px;
      font-weight: 400;
      color: #6e6d6d;
    }
  }
  .case {
    position: relative;
    .Viewing_area {
      width: 1919px;
      height: 900px;
      overflow: hidden;
      position: relative;
      .Thesliders {
        transition: all 0s !important;
      }
      .Theslider {
        position: absolute;
        top: 0;
        transition: all 1s;
        .brander {
          position: relative;
          .bg {
            width: 100%;
          }
          .body {
            position: absolute;
            top: 90px;
            left: 198px;
            z-index: 1;
            display: flex;
            .left {
              width: 10px;
              height: 228px;
              background: #a2112e;
            }
            .right {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              margin-left: 10px;
              p {
                width: 143px;
                font-size: 60px;
                font-weight: bold;
                color: #ffffff;
                line-height: 80px;
              }
              span {
                font-size: 30px;
                font-weight: 400;
                color: #ffffff;
                line-height: 45px;
              }
            }
          }
        }
      }
    }
    .box {
      position: absolute;
      z-index: 1;
      top: 400px;
      right: 30px;
      .dian {
        cursor: pointer;
        margin: 10px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: white;
      }
      .red {
        background-color: #a0112d;
      }
    }
  }
  .course {
    width: 1920px;
    height: 2090px;
    background: url("https://prod.oss.netopstec.com/net-about-course2-8-8.png")
      no-repeat top center;
    background-size: 100% 100%;
    position: relative;
    li {
      position: absolute;
      width: 120px;
      height: 120px;
      background: #fcfcfc;
      border-radius: 50%;
      &:hover {
        transform: translateY(-10px);
        transition: all 0.3s;
      }
      span {
        display: block;
        width: 120px;
        text-align: center;
        font-size: 40px;
        font-family: Impact;
        color: #a0112d;
        line-height: 120px;
      }
    }
    .li1 {
      left: 186px;
      top: 1651px;
    }
    .li2 {
      left: 246px;
      top: 1474px;
    }
    .li3 {
      left: 348px;
      top: 1291px;
    }
    .li4 {
      left: 449px;
      top: 1140px;
    }
    .li5 {
      left: 567px;
      top: 994px;
    }
    .li6 {
      left: 716px;
      top: 865px;
    }
    .li7 {
      left: 877px;
      top: 751px;
    }
    .li8 {
      left: 1047px;
      top: 636px;
    }
    .li9 {
      left: 1248px;
      top: 539px;
    }
    .li10 {
      left: 1483px;
      top: 460px;
    }

    h6 {
      width: 526px;
      height: 124px;
      line-height: 124px;
      background: url("https://prod.oss.netopstec.com/net-box_title-bg3.png")
        no-repeat center center;
      background-size: 100% 100%;
      align-items: center;
      text-align: center;
      position: absolute;
      top: 171px;
      left: 697px;
      font-size: 40px;
      font-weight: 500;
      color: #ffffff;
    }
    .xian {
      position: absolute;
      top: 453px;
      left: 191px;
      width: 1531px;
      height: 1413px;
    }
    span {
    opacity: 0;
    position: absolute;
    font-size: 18px;
    color: #fcfcfc;
    line-height: 45px;
  }
  .span1 {
    width: 200px;
    left: 342px;
    bottom: 364px;
  }
  .span2 {
    width: 600px;
    left: 392px;
    bottom: 519px;
  }
  .span3 {
    width: 500px;
    left: 493px;
    bottom: 694px;
  }
  .span4 {
    width: 500px;
    left: 599px;
    bottom: 841px;
  }
  .span5 {
    width: 350px;
    left: 715px;
    bottom: 992px;
  }
  .span6 {
    width: 600px;
    left: 863px;
    bottom: 1145px;
  }
  .span7 {
    width: 540px;
    left: 1039px;
    top: 775px;
  }
  .span8 {
    width: 500px;
    left: 550px;
    top: 640px;
    text-align: right;
  }
  .span9 {
    width: 400px;
    left: 820px;
    top: 545px;
    text-align: right;
  }
  .span10 {
    width: 350px;
    left: 1100px;
    top: 450px;
    text-align: right;
  }
  }
  .dynamic {
    position: relative;
    width: 1920px;
    height: 1647px;
    opacity: 0;
    background: url("https://prod.oss.netopstec.com/about-medal-bg2.png")
      no-repeat top center;
    background-size: 100% 100%;
    .box_title {
      background: none;
      color: #ffffff;
    }
    .xian {
      width: 14px;
      opacity: 0;
      position: absolute;
      right: 580px;
      bottom: 171px;
    }
    .left {
      position: absolute;
      left: 150px;
      opacity: 0;
      width: 1049px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;
      img {
        -webkit-box-reflect: below 2px -webkit-linear-gradient(transparent, transparent
              50%, rgba(255, 255, 255, 0.1));
        margin: 0 2% 58px;
        flex-grow: 0;
        width: 200px;
        image-rendering: pixelated;
        &:nth-child(5) {
          position: relative;
          top: 47px;
        }
        &:nth-child(11) {
          position: relative;
          top: 12px;
        }
        &:nth-child(12) {
          position: relative;
          top: 10px;
        }
        &:nth-child(1) {
          position: relative;
          top: 5px;
        }
        &:nth-child(2) {
          position: relative;
          top: 15px;
        }
        &:nth-child(4) {
          position: relative;
          top: 12px;
        }
        &:nth-child(8) {
          position: relative;
          top: 12px;
        }
        &:nth-child(7) {
          position: relative;
          top: 5px;
        }
      }
    }
    .right {
      opacity: 0;
      color: white;
      line-height: 30px;
      position: absolute;
      right: 215px;
      bottom: 210px;
      li {
        &:nth-last-child(1) {
          margin-bottom: 0;
        }
        margin-bottom: 63.5px;
        font-size: 18px;
      }
    }
  }
  .footer {
  width: 1920px;
  height: 175px;
  background: url("https://prod.oss.netopstec.com/net-about-foot-8-8.png") no-repeat
    top center;
  padding-top: 70px;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  line-height: 35px;
  .showDialog {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    width: 40%;
    margin: auto;
    margin-bottom: 0.2rem;
  }
  p {
    width: 100%;
  }
  a {
    color: #ffffff;
    text-decoration: none;
  }
}

}
@media screen and (max-width: 1100px) {
  .header1 {
    display: none;
  }
  .header2 {
    position: relative;
    .header-bg {
      width: 100%;
      position: relative;
      z-index: -1;
    }
    h4 {
      width: 750px;
      text-align: center;
      position: absolute;
      left: 0px;
      top: 365px;
      font-size: 70px;
      letter-spacing: 5px;
      font-weight: bold;
      color: #ffffff;
      line-height: 69px;
      text-shadow: 0 0 2px #e5dede;
      animation: bannerLogo 1.5s linear 1;
    }
    p {
      position: absolute;
      top: 501px;
      background-color: rgba(0, 0, 0, 0.4);
      text-align: center;
      left: 0;
      right: 0;
      margin: 0 auto;
      opacity: 0;
      padding: 23px;
      width: 651px;
      // height: 280px;
      font-size: 22px;
      font-weight: 400;
      color: #ffffff;
      line-height: 45px;
      animation: bannerLogo 1.5s 0.5s linear 1;
      animation-fill-mode: forwards;
    }
  }
  .box_title {
    background:url("https://prod.oss.netopstec.com/box_title-bg-h5.png")
        no-repeat center center ;
      background-size: 100% 100%;

    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    width: 750px;
    height: 324px;
    h6 {
      background: url("https://prod.oss.netopstec.com/net-box_title-bg3.png")
        no-repeat center center;
      background-size: 100% 100%;
      width: 350px;
      height: 83px;
      color: white;
      line-height: 83px;
      align-items: center;
      text-align: center;
      position: absolute;
      top: 126px;
      font-size: 40px;
      font-weight: 500;
      span {
        color: #982037;
      }
    }
    p {
      position: absolute;
      top: 0;
      margin-top: 306px;
      font-size: 25px;
      font-weight: 400;
      color: #6e6d6d;
    }
  }
  .case {
    position: relative;
    .Viewing_area {
      width: 750px;
      height: 957px;
      overflow: hidden;
      position: relative;
      .Thesliders {
        transition: all 0s !important;
      }
      .Theslider {
        position: absolute;
        top: 0;
        display: flex;
        transition: all 1s;
        .brander {
          position: relative;
          .bg {
            width: 750px;
            height: 957px;
          }
          .body {
            position: absolute;
            top: 90px;
            left: 48px;
            z-index: 1;
            display: flex;
            .left {
              width: 3px;
              height: 90px;
              background: #a2112e;
            }
            .right {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              margin-left: 10px;
              p {
                font-size: 40px;
                font-weight: bold;
                color: #ffffff;
                line-height: 40px;
              }
              span {
                margin-top: 10px;
                font-size: 30px;
                font-weight: 400;
                color: #ffffff;
                line-height: 45px;
              }
            }
          }
        }
      }
    }
    .box {
      position: absolute;
      z-index: 1;
      width: 100%;
      display: flex;
      justify-content: center;
      top: 884px;
      margin: 0 auto;
      .dian {
        cursor: pointer;
        margin: 10px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: white;
      }
      .red {
        background-color: #a0112d;
      }
    }
  }
  .course {
    width: 750px;
    height: 980px;
    background: url("https://prod.oss.netopstec.com/net-about-course2-8-8.png")
      no-repeat top center;
    background-size: 100% 100%;
    position: relative;
    .select {
      border: 5px solid white;
      background-color: #a0112d;
        span {
          color: #FFFFFF;
        }
      }
    li {
      position: absolute;
      width: 52px;
      height: 52px;
      background: #fcfcfc;
      border-radius: 50%;
      span {
        display: block;
        width: 52px;
        text-align: center;
        font-size: 23px;
        font-family: Impact;
        color: #a0112d;
        line-height: 52px;
      }
    }
    .li1 {
      left: 47px;
      top: 735px;
    }
    .li2 {
      left: 73px;
      top: 659px;
    }
    .li3 {
      left: 116px;
      top: 580px;
    }
    .li4 {
      left: 159px;
      top: 515px;
    }
    .li5 {
      left: 216px;
      top: 454px;
    }
    .li6 {
      left: 274px;
      top: 398px;
    }
    .li7 {
      left: 343px;
      top: 344px;
    }
    .li8 {
      left: 416px;
      top: 299px;
    }
    .li9 {
      left: 502px;
      top: 258px;
    }
    .li10 {
      left: 602px;
      top: 225px;
    }

    h6 {
      width: 350px;
      height: 83px;
      line-height: 83px;
      background: url("https://prod.oss.netopstec.com/net-box_title-bg3.png")
        no-repeat center center;
      background-size: 100% 100%;
      align-items: center;
      text-align: center;
      position: absolute;
      top: 125px;
      left: 200px;
      font-size: 40px;
      font-weight: 500;
      color: #ffffff;
    }
    .xian {
      position: absolute;
      top: 231px;
      left: 47px;
      width: 655px;
      height: 598px;
    }
    .span {
      text-align: center;
      top: 550px;
      left: 280px;
      opacity: 0;
      padding: 20px;
      position: absolute;
      font-size: 18px;
      color: #fcfcfc;
      line-height: 45px;
      width: 385px;
      // height: 169px;
      background: rgba(252, 252, 252, 0.2);
      border-radius: 10px;
    }
  }
  .dynamic {
    position: relative;
    width: 750px;
    height: 969px;
    opacity: 0;
    background: url("https://prod.oss.netopstec.com/about-medal-bg2-h52.png")
      no-repeat top center;
    background-size: 100% 100%;
    .box_title {
      background: none;
      color: #ffffff;
    }
    .xian {
      width: 14px;
      opacity: 0;
      position: absolute;
      right: 580px;
      bottom: 171px;
    }
    .left {
      position: absolute;
      opacity: 0;
      width: 750px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;
      div {
        width: 148px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin: 0 9px 30px;
        p {
          position: absolute;
          top: 180px;
          width: 148px;
          font-size: 16px;
          font-weight: 500;
          color: #ffffff;
          text-align: center;
        }
      }
      img {
        -webkit-box-reflect: below 2px -webkit-linear-gradient(transparent, transparent
              50%, rgba(255, 255, 255, 0.1));
        flex-grow: 0;
        width: 148px;
        image-rendering: pixelated;
        &:nth-child(5) {
          position: relative;
          top: 47px;
        }
        &:nth-child(11) {
          position: relative;
          top: 12px;
        }
        &:nth-child(12) {
          position: relative;
          top: 10px;
        }
        &:nth-child(1) {
          position: relative;
          top: 5px;
        }
        &:nth-child(2) {
          position: relative;
          top: 15px;
        }
        &:nth-child(4) {
          position: relative;
          top: 12px;
        }
        &:nth-child(8) {
          position: relative;
          top: 12px;
        }
        &:nth-child(7) {
          position: relative;
          top: 5px;
        }
      }
    }
  }
  .footer {
  width: 750px;
  height: 216px;
  background: url("https://prod.oss.netopstec.com/net-about-foot-8-8.png") no-repeat
    top center;
  padding-top: 100px;
  background-size: 750px 316px;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  line-height: 35px;
  p {
    width: 100%;
  }
  a {
    color: #ffffff;
    text-decoration: none;
  }
}

}

.box_title1_ini {
  opacity: 1;
  h6 {
    animation: introduce_title 1s linear 1;
  }
}

.gdxl {
  width: 58px;
  height: 38px;
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: 0;
  z-index: 1;
  animation: gdxl 2s infinite;
  cursor: pointer;
}

.case_ini {
  animation: service_ini 1s linear 1;
  animation-fill-mode: forwards;
}
.achievement {
  position: relative;
  .achievement-bg {
    width: 100%;
  }
  .main {
    opacity: 0;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    div:nth-child(2) {
      background: rgba(255, 255, 255, 0.25);
    }
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 507px;
      height: 244px;
      background: rgba(255, 255, 255, 0.15);
      span {
        margin-top: 10px;
        font-size: 37px;
        color: #ffffff;
      }
      p {
        margin-top: 10px;
        font-size: 24px;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}
.achievement_ini {
  .main {
    opacity: 1;
    animation: achievement_ini 1s linear 1;
  }
}

.course_ini {
  h6 {
    animation: introduce_title 1s linear 1;
  }
  span {
    animation: introduce_title 0.6s 0.7s linear 1;
    animation-fill-mode: forwards;
  }
}
.box_title2_ini {
  opacity: 1;
  h6 {
    animation: introduce_title 1s linear 1;
  }
}
.honor {
  display: flex;
  align-items: center;
  padding-bottom: 200px;
  justify-content: space-around;
}
.honor_ini {
  img {
    animation: introduce_title 1s linear 1;
  }
}

.dynamic_ini {
  animation: introduce_title 1s linear 1;
  animation-fill-mode: forwards;
  .left {
    animation: introduce_title 1s 0.7s linear 1;
    animation-fill-mode: forwards;
  }
  .right {
    animation: introduce_title 1s 0.7s linear 1;
    animation-fill-mode: forwards;
  }
  .xian {
    animation: introduce_title 1s 0.7s linear 1;
    animation-fill-mode: forwards;
  }
}
@keyframes introduce_title {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  50% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes bannerLogo {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  50% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes gdxl {
  0% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes service_ini {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  50% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes achievement_ini {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  50% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>