<template>
  <div v-if="show" class="modelbox">
    <div @click="hClone" class="box"></div>
    <div class="body">
      <!-- <img
        class="bg"
        :src="`https://img.netopstec.com/website` + showData.imgPath"
        alt=""
      /> -->
      <img v-if="IsPC()"
        class="bg"
        src="@/assets/dinamic-bg.jpg"
        alt=""
      />
       <img v-else
        class="bg"
        src="@/assets/dinamic-bg-h5.jpg"
        alt=""
      />
      <div class="model"></div>
      <div class="fixedTitle">
         <p style="">{{ showData.title }}</p>
         <span>{{ showData.publishTime }}</span> 
      </div>
      <div class="text">
        <div class="title">       
          <p v-html="showData.content" id="modelbox_main" class="main"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["show", "clone", "showData"],
  methods: {
    hClone() {
      this.clone();
    },
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    },
  },
  data: {},
  mounted() {
    console.log(document.querySelector("#main"));
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 1100px) {
  .modelbox {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 2;
    .box {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #2b070e;
      opacity: 0.8;
    }
    .body {
      top: 100px;
      position: absolute;
      margin: 0 auto;
      width: 1100px;
      left: 0;
      right: 0;
      height: auto;
    }
    .model {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
    }
    .bg {
      width: 100%;
    }
    .fixedTitle {
      position:absolute;
      top: 0;
      z-index: 3;
      margin: 10% 10% 0 10%;
      p {
        color: #000000; 
        font-size: 22px;
        font-weight: 900;
      }
    }
    .text {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      margin: 15% 10% 10% 10%;
      width: 80%;
      padding-right: 20px;
      height: 71%;
      // margin-left: 200px;
      overflow-y: auto;
      overflow-x: hidden;

      .title {
        p {
          color: #000000;
          font-size: 22px;
          font-weight: 900;
          margin-bottom: 10px;
        }
        span {
          color: #000000;
          font-size: 16px;
        }
        .main {
          font-weight: normal;
          margin-top: 30px;
          font-size: 16px;
          color: #000000;
          line-height: 30px;
        }
      }
    }
    .text::-webkit-scrollbar {
      margin-left: 10px;
      width: 2px;
      height: 250px;
      background: #000000;
      border: 1px solid #000000;
    }
    .text::-webkit-scrollbar-thumb {
      width: 2px;
      height: 56px;
      background: #9f112d;
    }
    // .text::-webkit-scrollbar-track-piece {

    // }
  }
}
@media screen and (max-width: 1100px) {
  .modelbox {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 2;
    .box {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #2b070e;
      opacity: 0.8;
    }
    .body {
      top: 300px;
      position: absolute;
      margin: 0 auto;
      width: 656px;
      left: 0;
      right: 0;
      height: auto;
    }
    .model {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
    }
    .bg {
      width: 100%;
    }
    .fixedTitle {
      position:absolute;
      top: 0;
      z-index: 3;
      margin: 5% 10%;
      p {
        color: #000000; 
        font-size: 22px;
        font-weight: 900;
      }
    }
    .text {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      margin: 10% 10% 10% 10%;
      width: 80%;
      padding-right: 20px;
      height: 78%;
      // margin-left: 200px;
      overflow-y: auto;
      overflow-x: hidden;

      .title {
        p {
          color: #000000;
          font-size: 22px;
          font-weight: 900;
          margin-bottom: 10px;
        }
        span {
          color: #000000;
          font-size: 16px;
        }
        .main {
          font-weight: normal;
          margin-top: 30px;
          font-size: 16px;
          color: #000000;
          line-height: 30px;
        }
      }
    }
    .text::-webkit-scrollbar {
      margin-left: 10px;
      width: 2px;
      height: 250px;
      background: #000000;
      border: 1px solid #000000;
    }
    .text::-webkit-scrollbar-thumb {
      width: 2px;
      height: 56px;
      background: #9f112d;
    }
    // .text::-webkit-scrollbar-track-piece {

    // }
  }
}
/deep/ #modelbox_main {
  img {
    width: 100%;
  }
  .ql-align-center {
    text-align: center;
  }
  .ql-align-left {
    text-align: left;
  }
  .ql-align-right {
    text-align: right;
  }
}
</style>